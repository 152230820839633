import axios from 'axios';

const getMessagesInstances = (queryParams, { mode, rawUrl }) => {
  const url = `/custom_messages.${mode}`;
  if (rawUrl) return axios.getUri({ url, params: queryParams });
  return axios.get(url, {
    params: queryParams,
  });
};

const fetchMessagesFilters = (queryParams) =>
  axios.get(`/filters/custom_message?${queryParams}`);

const fetchPreviewFilters = ({ messageInstanceId, isPreview }) =>
  axios.get(
    `/custom_messages/filters?message_instance_id=${messageInstanceId}&is_preview=${isPreview}`,
  );

const fetchPreviewFilterTypeaheadUrl = (
  field,
  messageInstanceId,
  messageGeneratableId,
) =>
  `/custom_messages/filters?is_preview=true&typeahead_field=${field}&message_generatable_id=${messageGeneratableId}&message_instance_id=${messageInstanceId}&search_term=`;

const fetchPreview = (messageInstanceId, queryParams = '', locale = 'en') =>
  axios.get(
    `/custom_messages/preview?message_instance_id=${messageInstanceId}&locale=${locale}&${queryParams}`,
  );

const confirmOrCancelAppointment = (encounterId, buttonType) =>
  axios.post(`/confirm_or_cancel_appointment.json`, {
    encounter_id: encounterId,
    button_type: buttonType,
  });

const fetchAppointmentEmail = (id, locale) => {
  let url = `/appointment_email.json?secured_message_id=${id}`;
  if (locale) {
    url += `&locale=${locale}`;
  }
  return axios.get(url);
};

// eslint-disable-next-line import/prefer-default-export
export {
  getMessagesInstances,
  fetchMessagesFilters,
  fetchPreviewFilters,
  fetchPreview,
  confirmOrCancelAppointment,
  fetchPreviewFilterTypeaheadUrl,
  fetchAppointmentEmail,
};
